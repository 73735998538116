// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { addCourseFirebase, addDocument, fetchDataList, fetchDataObject, fetchDocumentIds, getUsers, updateDocument, deleteFile, removeCourseAttendees, deleteDocument, deleteAssignmentFirebase } from '../services/FirebaseService'
import { assignmentCollection, auth, coursesCollection, firestore, gradesCollection, gradesScriptingCollection, medicationsCollection, conditionsCollection, pendingUsersCollection, questionnairesCollection, scenariosCollection, schoolsCollection, templatesCollection, usersCollection } from '../firebase';
import { useUser } from './UserContext';
import { arrayRemove, deleteDoc, doc, getDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { useNotification } from './NotificationContext';
import { ref, listAll, deleteObject } from 'firebase/storage';
import { storage, functions, httpsCallable } from '../firebase';

const AppDataContext = createContext();

export function useAppData() {
    return useContext(AppDataContext);
}

export function AppDataProvider({ children }) {
    const [courses, setCourses] = useState([]);
    const [grades, setGrades] = useState([]);
    const [school, setSchool] = useState(null);
    const [schools, setSchools] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [users, setUsers] = useState([]);
    const [pendingUsers, setPendingUsers] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [introductionCourse, setIntroductionCourse] = useState(null);
    const [medications, setMedications] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [gradeScript, setGradeScript] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { showNotification } = useNotification();
    const [filteredGrades, setFilteredGrades] = useState({});

    const user = useUser();

    function formatDate(date) {
        const d = new Date(date);
        let day = d.getDate();
        let month = d.getMonth() + 1; // Maanden zijn van 0-11 in JavaScript
        const year = d.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }

        if (month < 10) {
            month = '0' + month;
        }

        return `${day}/${month}/${year}`;
    }

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                setIsLoading(true);
                showNotification('info', 'Fetching user data...');

                const INTRODUCTION_COURSE_ID = "66Fqzp90Cib8lmwCN3QZ";

                // Stel een nieuwe array samen die de introductiecursus ID bevat samen met alle andere cursus IDs die de gebruiker bijwoont.
                const allCoursesIds = [INTRODUCTION_COURSE_ID, ...(user.attendingClasses || [])];

                // Gebruik deze nieuwe array om de lijst met cursussen op te halen.
                const courseList = await fetchDataList(allCoursesIds, coursesCollection);
                //console.log("AttendingClasses", courseList);
                setCourses(courseList);

                //User school
                if (user.schoolID) {
                    const schoolData = await fetchDataObject(user.schoolID, schoolsCollection);
                    setSchool(schoolData);
                }

                // Fetch pending users based on role
                if (user.roleName === "Admin") {
                    // Fetch all pending users for admins
                    const pendingUserDocs = await fetchDocumentIds(pendingUsersCollection);
                    const pendingUserList = await fetchDataList(pendingUserDocs, pendingUsersCollection);
                    setPendingUsers(pendingUserList);
                } else if (user.roleName === "Teacher" && user.schoolID) {
                    // Fetch pending users only for the teacher's school
                    const pendingUserDocs = await fetchDocumentIds(pendingUsersCollection);
                    const allPendingUsers = await fetchDataList(pendingUserDocs, pendingUsersCollection);
                    const schoolPendingUsers = allPendingUsers.filter(pu => pu.schoolID === user.schoolID);
                    setPendingUsers(schoolPendingUsers);
                }

                //Student only data
                // Make sure students also can see all the people that a Teacher can see!

                if (user.roleName === "Student") {
                    // Students should only be aware of themselves
                    setUsers([user]);
                }

                //Admin only data
                else if (user.roleName === "Admin") {
                    // Admins can see all users
                    const classroomIDs = await fetchDocumentIds(coursesCollection);
                    //console.log("Classroom " + classroomIDs);
                    const adminCourseList = await fetchDataList(classroomIDs, coursesCollection);
                    setCourses(adminCourseList);

                    // fetch regular users
                    const users = await getUsers();
                    setUsers(users);

                    // fetch pending users
                    const pendingUserDocs = await fetchDocumentIds(pendingUsersCollection);
                    const pendingUserList = await fetchDataList(pendingUserDocs, pendingUsersCollection);
                    setPendingUsers(pendingUserList);

                    const medicationIDs = await fetchDocumentIds(medicationsCollection);
                    const ms = await fetchDataList(medicationIDs, medicationsCollection);
                    setMedications(ms);

                    const conditionIDs = await fetchDocumentIds(conditionsCollection);
                    const cs = await fetchDataList(conditionIDs, conditionsCollection);
                    setConditions(cs);

                    const gradeScriptIDs = await fetchDocumentIds(gradesScriptingCollection);
                    const gs = await fetchDataList(gradeScriptIDs, gradesScriptingCollection);
                    setGradeScript(gs);
                }

                // Teacher and admin shared data
                if (user.roleName === "Teacher" || user.roleName === "Admin") {
                    const sIDs = await fetchDocumentIds(schoolsCollection);
                    const s = await fetchDataList(sIDs, schoolsCollection);
                    setSchools(s);

                    if (user.templates?.length > 0) {
                        const templateList = await fetchDataList(user.templates, templatesCollection);
                        setTemplates(templateList);
                    }
                }

                const qIDs = await fetchDocumentIds(questionnairesCollection);
                const qs = await fetchDataList(qIDs, questionnairesCollection);
                setQuestionnaires(qs);

                //showNotification('success', 'User data fetched successfully.');
            }
            setIsLoading(false);
        };

        fetchData();
    }, [user]);


    // useEffect(() => {
    //     const fetchAssignments = async () => {
    //         if (courses?.length > 0) {
    //             const assignmentsMap = courses.map(async (course) => {
    //                 return await fetchDataList(course.assessments, assignmentCollection);
    //             });

    //             const assignmentsArr = (await Promise.all(assignmentsMap)).flat();
    //             console.log(assignmentsArr);

    //             setAssignments(assignmentsArr);
    //             //showNotification('success', 'Assignments fetched successfully.');
    //         }
    //     }

    //     fetchAssignments();
    // }, [courses]);


    useEffect(() => {
        const fetchAssignments = async () => {
            if (courses?.length > 0) {
                try {
                    // Maak een map van alle cursussen waaraan de gebruiker deelneemt
                    const assignmentsMap = courses.map(async (course) => {
                        // Haal de assignments op die aan elke course zijn gekoppeld
                        return await fetchDataList(course.assessments, assignmentCollection);
                    });

                    // Wacht tot alle promises voltooid zijn en flatten de array
                    const assignmentsArr = (await Promise.all(assignmentsMap)).flat();
                    // console.log(assignmentsArr);
                    setAssignments(assignmentsArr); // Zet de opgehaalde assignments in de state
                    //showNotification('success', 'Assignments fetched successfully.');
                } catch (error) {
                    //console.error('Error fetching assignments:', error);
                    //showNotification('danger', 'Failed to fetch assignments.');
                }
            }
        };

        fetchAssignments();
    }, [courses]);


    const fetchFilteredGradesByCourse = async (courseId) => {
        if (users.length > 0) {
            const allGrades = await Promise.all(users.map(async (user) => {
                const userGrades = await fetchDataList(user.grades, gradesCollection);
                const filteredGrades = userGrades.filter(grade => grade.courseId === courseId); // Filter op courseId
                return [user.id, filteredGrades];
            }));

            const gradesMap = Object.fromEntries(allGrades);
            setFilteredGrades(gradesMap); // Alleen de gefilterde grades worden opgeslagen
        }
    };

    useEffect(() => {
        const fetchAllGrades = async () => {
            if (users.length > 0) {
                const allGrades = await Promise.all(users.map(async (user) => {
                    const userGrades = await fetchDataList(user.grades, gradesCollection);
                    return [user.id, userGrades];
                }));

                const gradesMap = Object.fromEntries(allGrades);
                setGrades(gradesMap);
            }
        };

        fetchAllGrades();
    }, [users]);

    useEffect(() => {
        const fetchSchoolData = async () => {
            if (school) {
                //Teachers can see all users in the school
                if (user.roleName === "Teacher" || user.roleName === "Student") {
                    const schoolUsers = await fetchDataList(school.attendees, usersCollection);
                    setUsers(schoolUsers);
                }

                const availableScenarios = await fetchDataList(school.availableScenarios, scenariosCollection);
                setScenarios(availableScenarios);
                //showNotification('success', 'School data fetched successfully.');
            }
        }

        fetchSchoolData();
    }, [school])

    const getUserListData = (ids) => {
        return users.filter(user => ids.includes(user.id));
    }

    const getUserData = (id) => {
        ////console.log("Fetching user data for ID:", id); // Check the ID being passed
        const user = users.find(user => user.id === id);
        if (user) {
            // //console.log("Found user:", user); // Verify the correct user object is being found
        } else {
            // //console.log("User not found for ID:", id); // This logs if the user is not found
        }
        return user;
    };



    const addCourse = async (courseName) => {
        const newCourse = {
            className: courseName,
            schoolID: user.schoolID,
            attendees: [user.id],
            creationDate: formatDate(Date.now()),
            published: false,
            admin: user.id
        };

        const id = await addCourseFirebase(user.id, newCourse);

        setCourses([...courses, { ...newCourse, id: id }]);
        showNotification('success', 'Course added successfully.');
    }

    const updateCourseThumbnail = async (courseId, newThumbnailColor) => {
        try {
            const updatedCourses = courses.map(course =>
                course.id === courseId
                    ? { ...course, thumbnailColor: newThumbnailColor }
                    : course
            );
            setCourses(updatedCourses);  // Update de course cache lokaal
        } catch (error) {
            console.error('Error updating course thumbnail:', error);
            showNotification('danger', 'Failed to update course thumbnail.');
        }
    };

    // const deleteCourse = async (course) => {
    //     //Update the cache
    //     const updatedUsers = users.map((old) => { return { ...old, attendingClasses: old.attendingClasses?.filter(a => a !== course.id) ?? [] } });
    //     setUsers(updatedUsers);

    //     //Update the cache
    //     setCourses(courses.filter(c => course.id != c.id));

    //     //Remove the course from the attending classes in the attendees
    //     for (const user of course.attendees) {
    //         await updateDocument(user, usersCollection, {
    //             attendingClasses: arrayRemove(course.id)
    //         });
    //     }

    //     //Delete the course from firebase
    //     await deleteDocument(course.id, coursesCollection);
    // }

    // New function checks if its an array or if its an empty value, it will then add a empty array to prevent runtime errors
    // const deleteCourse = async (course) => {
    //     // Ensure that attendingClasses is an array before filtering
    //     const updatedUsers = users.map((oldUser) => {
    //         const filteredClasses = Array.isArray(oldUser.attendingClasses)
    //             ? oldUser.attendingClasses.filter(classId => classId !== course.id)
    //             : [];
    //         return { ...oldUser, attendingClasses: filteredClasses };
    //     });
    //     setUsers(updatedUsers);

    //     // Update the courses cache
    //     setCourses(courses.filter(c => c.id !== course.id));

    //     // Check if course.attendees is iterable before looping
    //     if (Array.isArray(course.attendees)) {
    //         for (const userId of course.attendees) {
    //             await updateDocument(userId, usersCollection, {
    //                 attendingClasses: arrayRemove(course.id)
    //             });
    //         }
    //     }

    //     // Delete the course from firebase
    //     await deleteDocument(course.id, coursesCollection);
    //     showNotification('success', 'Course deleted successfully.');
    // }



    const deleteCourse = async (course) => {
        try {
            // Ensure that attendingClasses is an array before filtering
            const updatedUsers = users.map((oldUser) => {
                const filteredClasses = Array.isArray(oldUser.attendingClasses)
                    ? oldUser.attendingClasses.filter(classId => classId !== course.id)
                    : [];
                return { ...oldUser, attendingClasses: filteredClasses };
            });
            setUsers(updatedUsers);

            // Update the courses cache
            setCourses(courses.filter(c => c.id !== course.id));

            // Remove course from users' attendingClasses
            if (Array.isArray(course.attendees)) {
                await removeCourseAttendees(course.attendees, course.id);
            }

            // Fetch and delete related modules
            const modulesQuery = query(collection(firestore, 'modules'), where('courseId', '==', course.id));
            const modulesSnapshot = await getDocs(modulesQuery);
            const moduleDeletionPromises = modulesSnapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(moduleDeletionPromises);

            // Fetch and delete related assessments
            const assessmentsQuery = query(collection(firestore, 'assessments'), where('courseId', '==', course.id));
            const assessmentsSnapshot = await getDocs(assessmentsQuery);
            const assessmentDeletionPromises = assessmentsSnapshot.docs.map(doc => deleteAssignmentFirebase(course.id, doc.id));
            await Promise.all(assessmentDeletionPromises);

            // Delete related learning materials from Firebase Storage
            const learningMaterialsRef = ref(storage, `learningMaterials/${course.id}`);
            const listResults = await listAll(learningMaterialsRef);
            const deletePromises = listResults.items.map(itemRef => deleteObject(itemRef));
            await Promise.all(deletePromises);

            // Delete the course document from Firestore
            await deleteDocument(course.id, coursesCollection);

            showNotification('success', 'Course and related documents deleted successfully.');
        } catch (error) {
            console.error("Error deleting course and related documents: ", error);
            showNotification('danger', 'Failed to delete course and related documents.');
        }
    }

    const getClassRoomById = async (id) => {
        try {
            const docRef = doc(firestore, "classRooms", id); // Controleer de juiste naam van de collectie hier
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return { id: docSnap.id, ...docSnap.data() };
            } else {
                console.error("No such document!");
                return null;
            }
        } catch (error) {
            console.error("Error getting classroom:", error);
            return null;
        }
    };


    const deleteTemplate = async (templateId) => {
        try {
            const updatedTemplates = templates.filter((template) => template.id !== templateId);
            //console.log(updatedTemplates);
            setTemplates(updatedTemplates);

            await deleteDocument(templateId, templatesCollection);
            await updateDocument(user.id, usersCollection, { templates: arrayRemove(templateId) });

            showNotification('success', "Template has been deleted");
        } catch (error) {
            showNotification('danger', "Something went wrong!");
            showNotification('danger', error.message);
            //console.log(error);
        }
    }

    const addUser = async (newUser) => {
        try {
            const token = uuidv4(); // Generate a unique token for the invitation link
            await addDocument(newUser, pendingUsersCollection, token);

            // Voeg de nieuwe gebruiker toe aan de huidige lijst van pending users
            setPendingUsers((prevPendingUsers) => [
                ...prevPendingUsers,
                { ...newUser, isPending: true, id: token },
            ]);

            // Dit zorgt alleen maar voor problemen en verwarring
            // setUsers((prevUsers) => [
            //     ...prevUsers,
            //     { ...newUser, isPending: true, id: token },
            // ]);

            //console.log("Pending registration added with token: ", token);

            // Return the token as the userId
            return token;  // Dit zorgt ervoor dat het token (ID) beschikbaar is in je CreateUser component
        } catch (error) {
            console.error('Error adding user to pendingUsersCollection:', error);
            throw new Error('Failed to add user.');
        }
    };



    const deleteUser = async (user) => {
        try {
            if (!user.isPending) {
                try {
                    // Probeer de gebruiker uit Firebase Authentication te verwijderen
                    const deleteUserAccount = httpsCallable(functions, 'deleteUserAccount');
                    await deleteUserAccount({ uid: user.id });
                } catch (error) {
                    // Als de gebruiker niet bestaat in Authentication, log de fout maar ga verder
                    console.error(`Failed to delete user from Firebase Authentication: ${error.message}`);
                }

                // Verwijder de gebruiker uit de school
                await updateDocument(school.id, schoolsCollection, {
                    attendees: arrayRemove(user.id)
                });

                // Update de schoolcache
                setSchool({ ...school, attendees: school.attendees.filter(u => u !== user.id) });

                // Verwijder de gebruiker uit de attending courses, indien aanwezig
                if (Array.isArray(user.attendingClasses)) {
                    for (const courseId of user.attendingClasses) {
                        await updateDocument(courseId, coursesCollection, {
                            attendees: arrayRemove(user.id)
                        });
                    }
                } else {
                    //console.log(`No attending classes for user with ID ${user.id}`);
                }

                // Update de courses cache
                setCourses(courses.map(c =>
                    Array.isArray(user.attendingClasses) && user.attendingClasses.includes(c.id)
                        ? { ...c, attendees: c.attendees.filter(u => u !== user.id) }
                        : c
                ));

                // Verwijder de gebruiker uit de users-collectie
                await deleteDocument(user.id, usersCollection);
                setUsers(users.filter(u => u.id !== user.id));

            } else {
                // Annuleer de uitnodiging voor pending gebruikers
                await deleteDocument(user.id, pendingUsersCollection);
                setUsers(users.filter(u => u.id !== user.id));

                // Optioneel: Verwijder de gebruiker uit de pendingUsers cache
                // setPendingUsers(pendingUsers.filter(u => u.id !== user.id));
            }

            //showNotification('success', 'User deleted successfully.');
        } catch (error) {
            console.error('Error deleting user:', error);
            //showNotification('danger', 'Failed to delete user.');
        }
    };

    const deletePendingUser = async (user) => {
        try {
            // Verwijder de gebruiker uit de 'pendingUsersCollection'
            await deleteDocument(user.id, pendingUsersCollection);

            // Update de pendingUsers cache door de verwijderde gebruiker eruit te filteren
            setPendingUsers(pendingUsers.filter(u => u.id !== user.id));

            showNotification('success', 'Pending user deleted successfully.');
        } catch (error) {
            console.error('Error deleting pending user:', error);
            showNotification('danger', 'Failed to delete pending user.');
        }
    };


    const deleteGrade = async (grade, user) => {
        if (!user.isPending) {
            await updateDocument(user, usersCollection, {
                grades: arrayRemove(grade)
            })
            await deleteDocument(grade, gradesCollection);
            //console.log("Grade: " + grade + " was successfully removed")
            showNotification('success', 'Grade deleted successfully.');
        } else {
            //console.log("Grade could not be removed, something went wrong!")
            showNotification('danger', 'Failed to delete grade.');
        }
    }

    const addMedication = async (medication) => {
        const id = await addDocument(medication, medicationsCollection);
        setMedications((prev) => (prev ?? []).concat({ ...medication, id: id }));
        showNotification('success', 'Medication added successfully.');
        return id;
    }

    const deleteMedication = async (id) => {
        await deleteDocument(id, medicationsCollection);
        setMedications((prev) => prev.filter(m => m.id != id));
        showNotification('success', 'Medication deleted successfully.');
    }

    const addCondition = async (condition) => {
        const id = await addDocument(condition, conditionsCollection);
        setConditions([...conditions, { ...condition, id }]);
        showNotification('success', 'Condition added successfully.');
        return id;
    };

    const deleteCondition = async (id) => {
        await deleteDocument(id, conditionsCollection);
        setConditions(conditions.filter((cond) => cond.id !== id));
        showNotification('success', 'Condition deleted successfully.');
    };

    const addScriptedGrade = async (newGradeScript) => {
        const id = await addDocument(newGradeScript, gradesScriptingCollection);
        setGradeScript([...gradeScript, { ...newGradeScript, id }]);
        showNotification('success', 'GradeScript added successfully.');
        return id;
    };


    const deleteScriptedGrade = async (id) => {
        await deleteDocument(id, gradesScriptingCollection);
        setGradeScript(gradeScript.filter((cond) => cond.id !== id));
        showNotification('success', 'GradeScript deleted successfully.');
    };


    return (
        <AppDataContext.Provider value={{
            introductionCourse,
            courses,
            grades,
            setGrades,
            school,
            schools,
            setCourses,
            users,
            setUsers,
            pendingUsers,
            setPendingUsers,
            assignments,
            setAssignments,
            templates,
            setTemplates,
            scenarios,
            getUserData,
            getUserListData,
            addCourse,
            deleteCourse,
            deleteTemplate,
            addUser,
            deleteUser,
            questionnaires,
            setScenarios,
            setSchool,
            deleteGrade,
            medications,
            addMedication,
            deleteMedication,
            addCondition,
            deleteCondition,
            conditions,
            addScriptedGrade,
            deleteScriptedGrade,
            gradeScript,
            isLoading,
            getClassRoomById,
            deletePendingUser,
            updateCourseThumbnail,
            filteredGrades,
            fetchFilteredGradesByCourse,
        }}>
            {children}
        </AppDataContext.Provider>
    );
}

/* ONLY USE IF NECESSARY, SCHOOLS SHOULD NOW AUTOMATICALLY MANAGE ATTENDEES LIST
const updateSchoolWithUsers = async (id) => {
    //console.log("Fetching school data: " + id);
    const schoolData = await getSchool(id);

    const collectionRef = collection(firestore, 'users');
    const q = query(collectionRef, where('schoolID', '==', id));
    const querySnapshot = await getDocs(q);
    const users = querySnapshot.docs.map((doc) => {
        return doc.id;
    });

    const docRef = doc(firestore, 'schools', id);

    await updateDoc(docRef, {
        attendees: users,
        ...schoolData
    });
}
*/
