import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import PDFViewer from '../../../components/PDFViewer';
import { useScenario } from '../../scenarios/contexts/ScenarioContext';
import { updateDoc } from 'firebase/firestore';
import { useUser } from '../../../contexts/UserContext';
import { firestore } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import MultipleChoiceCard from '../../grades/components/MultipleChoiceCard';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { useAssessment } from '../contexts/AssessmentContext';

const Debriefing = ({ onContinue }) => {
    const [answers, setAnswers] = useState([]);
    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([]);
    const { scenario } = useAssessment(); // Use scenario
    const user = useUser();

    useEffect(() => {
        if (scenario && answers.length <= 0) {
            // Set default unanswered debriefing answers
            const emptyAnswers = Array(scenario.debriefing?.length).fill("unanswered");
            setAnswers(emptyAnswers);
        }
        if (scenario && multipleChoiceAnswers.length <= 0) {
            // Set default unanswered debriefing answers
            const emptyAnswers = Array(scenario.debriefing_2?.length).fill("unanswered");
            setMultipleChoiceAnswers(emptyAnswers);
        }
    }, [scenario]);

    const handleConfirm = async () => {
        const userDocRef = doc(firestore, 'users', user.id);
        const userDoc = await getDoc(userDocRef);
        const gradesArray = userDoc.data()?.grades || [];

        if (gradesArray.length > 0) {
            const lastGradeId = gradesArray[gradesArray.length - 1];
            const gradeRef = doc(firestore, 'grades', lastGradeId);

            // console.log(gradeRef);
            // console.log(answers);
            console.log(multipleChoiceAnswers);

            await updateDoc(gradeRef, {
                "debriefing": answers,
                "debriefing_2": multipleChoiceAnswers
            });
        }
        onContinue();
    };

    const handleAnswerChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = value;
        setAnswers(updatedAnswers);
    };

    const handleDebriefing2AnswerChange = (questionIndex, answerIndex) => {
        const newAnswers = [...multipleChoiceAnswers];
        newAnswers[questionIndex] = answerIndex;
        setMultipleChoiceAnswers(newAnswers);
    };

    return (
        <section className={'bg-white dark:bg-gray-900'}>
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 sm:py-16 lg:px-6">
                <div className="mt-4 md:mt-0">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                        Debriefing
                    </h2>
                    <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
                        Try to answer the following questions as extensively as possible.
                    </p>
                </div>
                {scenario?.debriefing?.map((item, index) => (
                    <div key={index} className="max-w-2xl mx-auto px-4">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">{item}</h2>
                        </div>
                        <form className="mb-6">
                            <div className="py-2 px-4 mb-4 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                                <label htmlFor="comment" className="sr-only">Your comment</label>
                                <textarea
                                    id="comment"
                                    rows="6"
                                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                                    className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                                    placeholder="Your answer..."
                                    required
                                ></textarea>
                            </div>
                        </form>
                    </div>
                ))}
                {scenario?.debriefing_2?.map((item, index) =>
                    <ClosedQuestionCard
                        key={index}
                        data={item}
                        onAnswerSelected={(answerIndex) => handleDebriefing2AnswerChange(index, answerIndex)}
                    />

                )}
                <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <button
                        onClick={handleConfirm}
                        className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                    >
                        Continue
                        <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Debriefing;


export const ClosedQuestionCard = ({ data, onAnswerSelected }) => {
    const [userAnswer, setUserAnswer] = useState(null);

    const handleAnswerClick = (index) => {
        if (userAnswer === null) {
            setUserAnswer(index);
            onAnswerSelected(index);
        }
    };

    const isCorrect = index => index === data.correctAnswer;
    const isSelected = index => index === userAnswer;

    return (
        <div className="mb-8 mt-8 p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">{data.question}</h2>
            <div className="grid gap-4">
                {data.answers?.map((choice, index) => (
                    <div
                        key={index}
                        onClick={() => handleAnswerClick(index)}
                        className={`relative flex items-center justify-between p-4 rounded-lg cursor-pointer transition-all
                        ${isSelected(index) ? "bg-blue-100 dark:bg-blue-200 border-blue-500" : "border border-gray-300 dark:border-gray-600"}
                        ${userAnswer !== null && !isSelected(index) ? "opacity-50" : ""}`}
                    >
                        <span className={`block w-full text-left dark:text-gray-200`}>
                            {choice}
                        </span>
                        {userAnswer !== null && (
                            <span className="absolute inset-y-0 right-4 flex items-center">
                                {isCorrect(index) ? (
                                    <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                ) : isSelected(index) ? (
                                    <XCircleIcon className="w-6 h-6 text-red-500" />
                                ) : null}
                            </span>
                        )}
                    </div>
                ))}
            </div>
            {userAnswer !== null && (
                <div className="mt-4 p-4 rounded-lg border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
                    <p className={`text-md font-semibold ${data.correctAnswer === userAnswer ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        {data.correctAnswer === userAnswer ? 'Your answer was Correct!' : 'Your answer was Incorrect!'}
                    </p>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Rationale:</h3>
                    <p className="mt-2 text-md text-gray-600 dark:text-gray-300">{data.rationale}</p>
                </div>
            )}
        </div>
    );
};
