import React, { useState, useEffect } from 'react';
import GradingDetails from '../grades/GradingDetails';
import Prebriefing from './sections/Prebriefing';
import Reflection from './sections/Reflection';
import Debriefing from './sections/Debriefing';
import { useAssessment } from './contexts/AssessmentContext'; // Zorg dat useAssessment wordt geïmporteerd
import Start from './sections/Start';
import { useUser } from '../../contexts/UserContext';
import { useNotification } from '../../contexts/NotificationContext';
import { GradingProvider } from '../grades/contexts/GradingContext';
import Performance from './sections/Performance';
import { getDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';

export default function SimulationDetails() {
  const { assessment, scenario } = useAssessment(); // Haal zowel assessment als scenario op
  const user = useUser();
  const { showNotification } = useNotification();
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 5; // Aantal stappen in de stepper
  const [grade, setGrade] = useState(null);
  let skipGradeCheck = false;

  const stepsTitles = ['Prebriefing', 'Start', 'Grades', 'Debriefing', 'Reflection'];

  // Logging voor assessment en scenario data
  useEffect(() => {
    console.log('Current Assessment Data:', assessment);
    if (scenario) {
      console.log('Fetched Scenario Data:', scenario);
    } else {
      console.log('No scenario data available yet.');
    }
  }, [assessment, scenario]);

  // Maak stappen klikbaar en verander direct naar de geselecteerde stap, maar met restricties op basis van grade
  const goToStep = (step) => {
  
    if (!skipGradeCheck && !grade && step > 2) {
      showNotification('warning', 'You cannot proceed to this step without completing the assignment.');
      return;
    }
    setCurrentStep(step);
  };

  const getStepIcon = (step) => {
    return step < currentStep ? (
      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
      </svg>
    ) : (
      <span className="me-2">{step}</span>
    );
  };

  const getStepClass = (step) => {
    let classes = `cursor-pointer flex md:w-full items-center ${step === currentStep ? 'text-blue-600 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'} sm:after:content-[''] after:w-full after:h-1 ${step < currentStep ? 'after:border-b after:border-blue-600 dark:after:border-blue-500' : 'after:border-b after:border-gray-200 dark:after:border-gray-700'} after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10`;
    if (step === totalSteps) classes += ' sm:after:content-none'; // Verwijdert de streep na de laatste stap
    return classes;
  };

  const handleViewPerformance = async () => {
    if(skipGradeCheck){
      goToStep(3);
      return;
      // this is for debugging purposes
    }
    const userDocRef = doc(firestore, 'users', user.id);
    const userDoc = await getDoc(userDocRef);
    const gradesArray = userDoc.data()?.grades || [];
    if (gradesArray.length > 0) {
      const lastGradeId = gradesArray[gradesArray.length - 1];
      const gradeRef = doc(firestore, 'grades', lastGradeId);
      const gradeDoc = await getDoc(gradeRef);

      if (gradeDoc.exists()) {
        const assessmentId = gradeDoc.data().assessmentID;
        if (assessmentId === assessment.id) {
          setGrade(gradeDoc.data());
          skipGradeCheck = true;
          goToStep(3); // Ga naar de "Grades" stap
        } else {
          showNotification('danger', "You have not completed the assignment yet");
        }
      } else {
        showNotification('danger', "You have not completed the assignment yet");
      }
    } else {
      showNotification('danger', "You have not completed the assignment yet");
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return <Prebriefing onContinue={() => goToStep(2)} />;
      case 2:
        return <Start onReturn={() => goToStep(1)} onContinue={handleViewPerformance} />;
      case 3:
        return <GradingProvider optionalGrade={grade}> <Performance onContinue={() => goToStep(4)} /> </GradingProvider>;
      case 4:
        return <Debriefing onContinue={() => goToStep(5)} />;
      case 5:
        return <GradingProvider optionalGrade={grade}><Reflection /></GradingProvider>;
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900 py-4 lg:py-8 antialiased">
      <h2 className="mb-4 text-6xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">
        {assessment ? assessment.assignmentName : "Loading..."}
      </h2>

      <div className="pt-10 max-w-none mx-auto px-8">
        {/* Stepper */}
        <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
          {stepsTitles.map((title, index) => (
            <li key={title} className={getStepClass(index + 1)} onClick={() => goToStep(index + 1)}>
              <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                {getStepIcon(index + 1)}
                {title}
              </span>
            </li>
          ))}
        </ol>
      </div>
      <div className="step-content">
        {renderStepContent(currentStep)}
      </div>
    </section>
  );
}