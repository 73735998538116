import React, { useState } from 'react';

// Function to format numbers with commas
const formatNumber = (num) => {
  return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const SalesCalculator = () => {
  const [wageWill, setWageWill] = useState(0);
  const [wageTimMilan, setWageTimMilan] = useState(0);
  const [commissionPercentage, setCommissionPercentage] = useState(0);
  const [expectedCost, setExpectedCost] = useState(0);
  const [sales, setSales] = useState([]);
  const [newSale, setNewSale] = useState('');
  const [saleDescription, setSaleDescription] = useState('');

  // Add a new sale to the sales array with an optional description
  const addSale = () => {
    if (newSale) {
      setSales([...sales, { amount: parseFloat(newSale), description: saleDescription }]);
      setNewSale('');
      setSaleDescription('');
    }
  };

  // Remove a sale from the sales array by index
  const removeSale = (index) => {
    const updatedSales = sales.filter((_, i) => i !== index);
    setSales(updatedSales);
  };

  // Calculate total sales commission for Tim and Milan
  const calculateCommission = () => {
    return sales.reduce((acc, sale) => acc + sale.amount * (commissionPercentage / 100), 0);
  };

  // Calculate total income from sales
  const calculateTotalIncome = () => {
    return sales.reduce((acc, sale) => acc + sale.amount, 0);
  };

  // Calculate the Inworld AI cost
  const calculateInworldAICost = (totalIncome) => {
    return totalIncome > 500000 ? totalIncome * 0.06 : 30000;
  };

  // Convert monthly wage to yearly wage
  const calculateYearlyCosts = (monthlyCost) => {
    return monthlyCost * 12;
  };

  // Calculate the total yearly costs
  const calculateTotalCosts = () => {
    const totalWages = calculateYearlyCosts(wageWill) + calculateYearlyCosts(wageTimMilan);
    const commission = calculateCommission();
    return totalWages + commission + expectedCost;
  };

  // Calculate how long the business can sustain itself with the current income
  const calculateMonthsOfOperation = (totalIncome, totalCosts) => {
    return (totalIncome / totalCosts) * 12;
  };

  // Calculate the summary including yearly income, costs, and final results
  const calculateSummary = () => {
    const totalIncome = calculateTotalIncome();
    const willYearlyIncome = calculateYearlyCosts(wageWill);
    const timMilanYearlyIncome = calculateYearlyCosts(wageTimMilan) + calculateCommission(); // Sales commission included
    const totalWages = willYearlyIncome + timMilanYearlyIncome;
    const totalCosts = totalWages + expectedCost;
    const inworldAICost = calculateInworldAICost(totalIncome);
    const finalResult = totalIncome - totalCosts - inworldAICost;
    const monthsOfOperation = calculateMonthsOfOperation(totalIncome, totalCosts);

    return {
      totalIncome,
      willYearlyIncome,
      timMilanYearlyIncome,
      totalWages,
      expectedCost,
      inworldAICost,
      totalCosts,
      finalResult,
      monthsOfOperation,
    };
  };

  const summary = calculateSummary();

  return (
    <div className="container mx-auto p-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-center mb-8">Courseta Financial Calculator</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div>
          <label className="block text-lg font-medium mb-2">Will's Monthly Salary:</label>
          <input
            type="number"
            value={wageWill}
            onChange={(e) => setWageWill(parseFloat(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Will's salary"
          />
        </div>

        <div>
          <label className="block text-lg font-medium mb-2">Tim and Milan's Monthly Salary:</label>
          <input
            type="number"
            value={wageTimMilan}
            onChange={(e) => setWageTimMilan(parseFloat(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Tim & Milan's salary"
          />
        </div>

        <div>
          <label className="block text-lg font-medium mb-2">Sales Commission Percentage (% per sale):</label>
          <input
            type="number"
            value={commissionPercentage}
            onChange={(e) => setCommissionPercentage(parseFloat(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="e.g., 8.75"
          />
        </div>

        <div>
          <label className="block text-lg font-medium mb-2">Expected Costs (per year):</label>
          <input
            type="number"
            value={expectedCost}
            onChange={(e) => setExpectedCost(parseFloat(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter expected costs"
          />
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-lg font-medium mb-2">Add a Sale:</label>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
          <input
            type="number"
            value={newSale}
            onChange={(e) => setNewSale(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter sale amount"
          />
          <input
            type="text"
            value={saleDescription}
            onChange={(e) => setSaleDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter sale description (optional)"
          />
          <button
            onClick={addSale}
            className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          >
            Add Sale
          </button>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Sales:</h2>
        <div className="space-y-4">
          {sales.map((sale, index) => (
            <div key={index} className="bg-white p-4 rounded-md shadow-md flex justify-between items-center">
              <div>
                <p className="text-lg font-medium">Sale: ${formatNumber(sale.amount)}</p>
                {sale.description && <p className="text-sm text-gray-500">Description: {sale.description}</p>}
              </div>
              <button
                onClick={() => removeSale(index)}
                className="text-red-500 hover:text-red-700 text-sm"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white p-6 rounded-md shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Summary</h2>
        <p className="text-lg">Total Income: <span className="font-bold">${formatNumber(summary.totalIncome)}</span></p>
        
        <div className="mt-4">
          <h3 className="text-xl font-semibold mb-2">All Wages:</h3>
          <p>Will's Yearly Salary: <span className="font-bold">${formatNumber(summary.willYearlyIncome)}</span></p>
          <p>Tim & Milan's Yearly Salary (with commission): <span className="font-bold">${formatNumber(summary.timMilanYearlyIncome)}</span></p>
        </div>

        <div className="mt-4">
          <h3 className="text-xl font-semibold mb-2">Costs:</h3>
          <p>Total Salaries: <span className="font-bold">${formatNumber(summary.totalWages)}</span></p>
          <p>Expected Additional Costs(Business Flights, other contractors, anything): <span className="font-bold">${formatNumber(summary.expectedCost)}</span></p>
          <p>Annual Inworld AI Costs: <span className="font-bold">${formatNumber(summary.inworldAICost)}</span></p>
        </div>

        {/* <div className="mt-4">
          <h3 className="text-xl font-semibold mb-2">Additional Costs:</h3>
          <p>Annual Inworld AI Costs: <span className="font-bold">${formatNumber(summary.inworldAICost)}</span></p>
        </div> */}

        <p className="text-lg mt-4">Total Yearly Costs: <span className="font-bold">${formatNumber(summary.totalCosts)}</span></p>
        <p className="text-lg">Final Result (Annual Profit/Loss): <span className="font-bold">${formatNumber(summary.finalResult)}</span></p>

        <div className="mt-4">
          <h3 className="text-xl font-semibold mb-2">Sustainability:</h3>
          <p>Months of Operation with Current Income: <span className="font-bold">{summary.monthsOfOperation.toFixed(2)}</span> months</p>
        </div>
      </div>
    </div>
  );
};

export default SalesCalculator;
