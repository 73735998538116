import React, { useState, useRef, useEffect } from "react";
import { useUser } from "../contexts/UserContext";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaVrCardboard } from 'react-icons/fa';
import logo from '../assets/images/Courseta_Logo.png';
import { addDocument } from "../services/FirebaseService";
import { tokenCollection } from "../firebase";
import { Timestamp } from "firebase/firestore";
import { usePopUp } from "../contexts/PopUpContext";
import SignInCodePopup from "../components/SignInCode";

export default function Header() {
    const user = useUser();
    const { logout } = useAuth();
    const { showPopUp } = usePopUp();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const navigate = useNavigate();

    const defaultProfileURL = "https://ui-avatars.com/api/?name=John+Doe&color=7F9CF5&background=EBF4FF";

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = async () => {
        await logout();
        navigate("/signin");
    };

    async function generateLoginCode() {
        const code = Math.floor(1000 + Math.random() * 9000);
        const expiration = Timestamp.fromDate(new Date(Date.now() + 5 * 60 * 1000));

        await addDocument({
            uid: user.id,
            expiration,
        }, tokenCollection, code.toString());

        showPopUp(<SignInCodePopup code={code} onClose={() => showPopUp(null)} />);
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-20">
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <img src={logo} className="mr-13 pl-10 h-16" alt="Courseta Logo" />
                </div>

                <div className="flex items-center space-x-4">
                    <button
                        type="button"
                        onClick={generateLoginCode}
                        className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 hover:scale-105 shadow-md transition-transform duration-300 ease-in-out"
                        style={{ height: '40px', marginRight: '10px' }}
                    >
                        <FaVrCardboard className="mr-2" />
                        Sign in to VR
                    </button>

                    <div className="relative inline-block text-left">
                        <button
                            ref={buttonRef}
                            type="button"
                            className="flex items-center p-2 text-sm bg-white dark:bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                            id="user-menu-button"
                            aria-expanded={isDropdownOpen ? "true" : "false"}
                            onClick={toggleDropdown}
                        >
                            <span className="sr-only">Open user menu</span>
                            <img
                                src={user?.photo_url ?? defaultProfileURL}
                                className="h-10 w-10 rounded-full object-cover"
                                alt="user profile"
                            />
                            <div className="flex flex-col ml-3 text-left">
                                <span className="text-base font-semibold text-gray-900 dark:text-white">
                                    {user?.firstName} {user?.lastName}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                    {user?.roleName}
                                </span>
                            </div>
                            <svg
                                className="w-4 h-4 ml-2 text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </button>
                        {isDropdownOpen && (
                            <div
                                ref={dropdownRef}
                                className="absolute right-0 mt-2 w-48 origin-top-right bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md shadow-lg py-1 z-50"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="user-menu-button"
                            >
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/main/profile');
                                    }}
                                    className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                                    role="menuitem"
                                >
                                    My profile
                                </a>
                                <a
                                    href="#"
                                    onClick={handleLogout}
                                    className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                                    role="menuitem"
                                >
                                    Sign out
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
}
