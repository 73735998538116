import React, { useEffect, useState } from 'react';
import { usePopUp } from '../../../contexts/PopUpContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import ScenarioFilter from '../components/ScenarioFilter';
import { HiClipboard, HiDocumentText } from "react-icons/hi";
import UpcomingAssignments from '../../../data/UpcomingAssignments';
import LatestSubmissions from '../../../data/LatestSubmissions';

const Overview = ({ course, setMatchingGrades, addScenarios, removeScenarios, handleCreateAssignment, setActiveTab }) => {
    const { showPopUp } = usePopUp();
    const { getUserData } = useAppData();
    const [adminInfo, setAdminInfo] = useState(null);
    const [courseAnalytics, setCourseAnalytics] = useState({
        totalAssignments: 0,
        overallSafety: 'N/A',
        overallAccuracy: 'N/A',
        overallCommunication: 'N/A',
        overallAverage: 'N/A',
        totalGrades: 0,
    });

    useEffect(() => {
        if (course?.admin) {
            const adminData = getUserData(course.admin);
            setAdminInfo(adminData);
        }
    }, [course?.admin, getUserData]);

    useEffect(() => {
        const fetchGradesForCourse = async () => {
            if (course && course.id) {
                try {
                    const gradesCollection = collection(firestore, 'grades');
                    const q = query(gradesCollection, where('courseId', '==', course.id));
                    const querySnapshot = await getDocs(q);

                    let totalSafety = 0;
                    let totalAccuracy = 0;
                    let totalCommunication = 0;
                    let countSafety = 0;
                    let countAccuracy = 0;
                    let countCommunication = 0;
                    let matchingGrades = [];

                    querySnapshot.forEach((doc) => {
                        const grade = doc.data();
                        matchingGrades.push(grade);

                        if (grade.safety !== undefined) {
                            totalSafety += grade.safety;
                            countSafety++;
                        }
                        if (grade.accuracy !== undefined) {
                            totalAccuracy += grade.accuracy;
                            countAccuracy++;
                        }
                        if (grade.communication !== undefined) {
                            totalCommunication += grade.communication;
                            countCommunication++;
                        }
                    });

                    const overallSafety = countSafety > 0 ? (totalSafety / countSafety).toFixed(1) : 'N/A';
                    const overallAccuracy = countAccuracy > 0 ? (totalAccuracy / countAccuracy).toFixed(1) : 'N/A';
                    const overallCommunication = countCommunication > 0 ? (totalCommunication / countCommunication).toFixed(1) : 'N/A';
                    const overallAverage = (countSafety + countAccuracy + countCommunication) > 0
                        ? ((totalSafety + totalAccuracy + totalCommunication) / (countSafety + countAccuracy + countCommunication)).toFixed(1)
                        : 'N/A';

                    setCourseAnalytics({
                        totalAssignments: course?.assessments?.length,
                        overallSafety,
                        overallAccuracy,
                        overallCommunication,
                        overallAverage,
                        totalGrades: matchingGrades.length
                    });

                    setMatchingGrades(matchingGrades); // Matching grades doorgeven via prop

                } catch (error) {
                    console.error("Error fetching grades:", error);
                }
            }
        };

        fetchGradesForCourse();
    }, [course, setMatchingGrades]); // Voeg setMatchingGrades toe aan de dependency array

    const handleOpenLab = () => {
        showPopUp(<ScenarioFilter course={course} addScenarios={addScenarios} removeScenarios={removeScenarios} />);
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-3xl font-bold text-gray-900">Course Overview</h2>
                <div className="space-x-2 flex">
                    <button
                        type="button"
                        className="flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300 space-x-2"
                        onClick={handleCreateAssignment}
                    >
                        <span>Create Assignment</span>
                        <HiClipboard className="w-5 h-5" />
                    </button>
                    <button
                        type="button"
                        className="flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300 space-x-2"
                        onClick={handleOpenLab}
                    >
                        <span>Open Lab</span>
                        <HiDocumentText className="w-5 h-5" />
                    </button>
                </div>

            </div>

            <div className="bg-white p-6 rounded-lg shadow mb-6">
                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Admin</h3>
                        <p className="text-lg font-semibold text-gray-900">
                            {adminInfo ? `${adminInfo.firstName} ${adminInfo.lastName}` : 'No Admin for this course'}
                        </p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Students</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.attendees?.length || 0}</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Date Created</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.creationDate || '09/01/2024'}</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Assignments</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.assessments?.length || 0}</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Grades</h3>
                        <p className="text-lg font-semibold text-gray-900">{courseAnalytics.totalGrades || 0}</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Status</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.published ? 'Published' : 'Not Published'}</p>
                    </div>
                </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow mb-6">
                <h3 className="text-xl font-bold text-gray-900 mb-4">Course Analytics</h3>
                <div className="grid grid-cols-4 gap-6 text-center">
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">Overall Safety</h4>
                        <p className="text-2xl font-bold text-green-600">{courseAnalytics.overallSafety}%</p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">Overall Accuracy</h4>
                        <p className="text-2xl font-bold text-blue-600">{courseAnalytics.overallAccuracy}%</p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">Overall Communication</h4>
                        <p className="text-2xl font-bold text-green-600">{courseAnalytics.overallCommunication}%</p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">Overall Average</h4>
                        <p className="text-2xl font-bold text-blue-600">{courseAnalytics.overallAverage}%</p>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                <div>
                    <UpcomingAssignments courseId={course?.id} limit={10} seeAllPath={() => setActiveTab(4)} />
                </div>
                <div>
                    <LatestSubmissions limit={10} courseId={course?.id} seeAllPath={() => setActiveTab(6)} />
                </div>
            </div>
        </div>
    );
};

export default Overview;
