import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useNotification } from '../../contexts/NotificationContext';
import defaultImage from '../../assets/images/2.png';
import { useUser } from '../../contexts/UserContext';
import { Accordion, Breadcrumb } from 'flowbite-react';
import { HiHome } from "react-icons/hi";
import { useNavigate } from 'react-router';
import { usePopUp } from '../../contexts/PopUpContext';
import UserInfo from '../user_profile/UserInfo_Popup';

export default function SchoolDetails() {
  const [school, setSchool] = useState(null);
  const [allScenarios, setAllScenarios] = useState([]);
  const { schoolId } = useParams();
  const { showNotification } = useNotification();
  const user = useUser();
  const [attendees, setAttendees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAttendees, setFilteredAttendees] = useState([]);
  const navigate = useNavigate();
  const { showPopUp } = usePopUp();

  useEffect(() => {
    const fetchSchoolAndAttendees = async () => {
      const schoolRef = doc(firestore, 'schools', schoolId);
      const schoolSnap = await getDoc(schoolRef);

      if (schoolSnap.exists()) {
        const schoolData = schoolSnap.data();
        setSchool({ ...schoolData, id: schoolSnap.id });

        if (schoolData.attendees && schoolData.attendees.length > 0) {
          await fetchAttendeesInBatches(schoolData.attendees);
        }
      } else {
        showNotification('danger', 'School not found.');
      }
    };

    const fetchScenarios = async () => {
      const scenariosSnapshot = await getDocs(collection(firestore, 'scenarios'));
      const scenariosList = scenariosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllScenarios(scenariosList);
    };

    fetchSchoolAndAttendees();
    fetchScenarios();
  }, [schoolId, showNotification]);

  const fetchAttendeesInBatches = async (attendeeIds) => {
    const batchSize = 30; // Firestore allows a maximum of 30 'in' queries
    let allAttendees = [];

    for (let i = 0; i < attendeeIds.length; i += batchSize) {
      const batch = attendeeIds.slice(i, i + batchSize);
      const usersCollectionRef = collection(firestore, 'users');
      const q = query(usersCollectionRef, where('id', 'in', batch));
      const querySnapshot = await getDocs(q);

      const attendeesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      allAttendees = [...allAttendees, ...attendeesList];
    }

    setAttendees(allAttendees);
    setFilteredAttendees(allAttendees);
  };

  const handleCapacityChange = (e) => {
    setSchool({ ...school, capacity: e.target.value });
  };

  const handleScenarioChange = (scenarioId) => {
    const availableScenarios = Array.isArray(school.availableScenarios) ? school.availableScenarios : [];
    const updatedScenarios = availableScenarios.includes(scenarioId)
      ? availableScenarios.filter(id => id !== scenarioId)
      : [...availableScenarios, scenarioId];

    setSchool({ ...school, availableScenarios: updatedScenarios });
  };

  const handleUpdateSchool = async () => {
    try {
      const schoolRef = doc(firestore, 'schools', schoolId);
      await updateDoc(schoolRef, {
        ...school,
        capacity: Number(school.capacity),
        availableScenarios: school.availableScenarios,
      });
      showNotification('success', 'School updated successfully.');
    } catch (error) {
      showNotification('danger', 'Failed to update school.');
    }
  };

  const handleSearchAttendees = (e) => {
    const search = e.target.value.toLowerCase();
    setSearchTerm(search);
    if (search) {
      setFilteredAttendees(
        attendees.filter((attendee) =>
          `${attendee.firstName} ${attendee.lastName}`.toLowerCase().includes(search) ||
          attendee.email.toLowerCase().includes(search)
        )
      );
    } else {
      setFilteredAttendees(attendees);
    }
  };

  const handleAttendeeClick = (attendee) => {
    // Voeg logging toe om te controleren of het attendee-object juist wordt doorgegeven
    console.log("Attendee clicked:", attendee);

    // Zorg ervoor dat de attendee-ID bestaat voordat je de popup toont
    if (attendee && attendee?.id) {
      showPopUp(<UserInfo user={attendee} onClose={() => showPopUp(null)} userRole={user?.roleName} />);
    } else {
      console.error("Attendee ID is undefined:", attendee);
    }
  };

  if (!school) return <div>Loading...</div>;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-2">
          <HiHome className="text-4xl text-gray-700 dark:text-gray-300" />
          <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">School Details</h1>
        </div>
        <button
          onClick={handleUpdateSchool}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update School
        </button>
      </div>

      {/* Capacity Input */}
      <div className="mb-4">
        <label htmlFor="capacity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Capacity:</label>
        <input
          type="number"
          id="capacity"
          value={school.capacity}
          onChange={handleCapacityChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
        />
      </div>

      <Accordion className="my-4">
        <Accordion.Panel>
          <Accordion.Title>
            Available Scenarios
          </Accordion.Title>
          <Accordion.Content>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
              {allScenarios
                .filter(scenario => scenario.fromCourseta === true)  // Filter scenarios with fromCourseta === true
                .map(scenario => (
                  <label key={scenario.id} className="inline-flex items-center mt-3">
                    <input
                      type="checkbox"
                      checked={school?.availableScenarios?.includes(scenario.id)}
                      onChange={() => handleScenarioChange(scenario.id)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{scenario.name}</span>
                  </label>
                ))}
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>


      {/* Attendees Search Bar */}
      {user?.roleName !== "Student" && (
        <div className="mt-6">
          <h3 className="text-xl font-semibold dark:text-white mb-4">Attendees</h3>
          <input
            type="text"
            placeholder="Search attendees"
            className="block p-2 text-sm border border-gray-300 rounded-lg w-full mb-4 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            value={searchTerm}
            onChange={handleSearchAttendees}
          />
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800 p-4">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {filteredAttendees.length > 0 ? (
                filteredAttendees.map((attendee, index) => (
                  <li
                    key={index}
                    className="py-3 sm:py-4 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => handleAttendeeClick(attendee)} // Zorg dat hele attendee-object wordt doorgegeven
                  >
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <img className="w-8 h-8 rounded-full" src={attendee.photo_url || defaultImage} alt={`${attendee.firstName} ${attendee.lastName} profile image`} />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                          {attendee.firstName} {attendee.lastName}
                        </p>
                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                          {attendee.email}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="text-center py-12">
                  <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="No attendees" />
                  <p className="text-gray-700 dark:text-white font-medium text-lg">No Attendees to display.</p>
                  <p className="text-gray-500 dark:text-gray-400">Fill this school by sending invites!</p>
                </div>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
