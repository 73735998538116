import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../../contexts/AppDataContext';
import { HiHome, HiUsers, HiPlus, HiOutlineTrash, HiOutlineEye, HiDownload, HiOutlineAcademicCap } from 'react-icons/hi';
import { useUser } from '../../contexts/UserContext';
import { useNotification } from '../../contexts/NotificationContext';
import Pagination from '../../components/Pagination';
import { useOverview } from '../../contexts/OverviewContext';
import defaultImage from '../../assets/images/22.png';
import SkeletonLoader from '../../components/SkeletonLoader';
import GradingDetails from '../grades/GradingDetails';
import { usePopUp } from '../../contexts/PopUpContext';
import { GradingProvider } from '../grades/contexts/GradingContext';
import { PDFDocument } from '../grades/components/PdfFormat';
import logo from "../../assets/images/Courseta_Logo.png";
import { BlobProvider, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { TailSpin } from 'react-loader-spinner';

export default function GradesOverview({ courseId = null }) {
  const currentUser = useUser();
  const { grades, getUserData, assignments, deleteGrade, isLoading, getClassRoomById } = useAppData();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { showPopUp } = usePopUp();
  const {
    searchQuery,
    currentPage,
    itemsPerPage,
    handleSearchChange,
    handlePageChange,
    handleNumItemsPerPageChange,
    setSearchQuery,
    setSortConfig,
    setCurrentPage,
    sortConfig,
    handleSort
  } = useOverview();

  const [filteredGrades, setFilteredGrades] = useState([]);
  const [classRooms, setClassRooms] = useState({});

  useEffect(() => {
    setSearchQuery('');
    setSortConfig({ key: 'name', direction: 'ascending' });
    return () => {
      setSearchQuery('');
      setSortConfig({ key: 'name', direction: 'ascending' });
    };
  }, [setSearchQuery, setSortConfig, setCurrentPage]);

  useEffect(() => {
    const fetchData = async () => {
      let filtered;

      if (currentUser.roleName === 'Student') {
        // Zorg ervoor dat studenten alleen hun eigen grades zien
        filtered = { [currentUser.id]: grades[currentUser.id] || [] };
      } else {
        // Voor docenten of admins worden alle grades getoond met mogelijke filtering op courseId
        filtered = Object.keys(grades).reduce((acc, userId) => {
          const user = getUserData(userId);
          if (!user) return acc;

          const fullName = user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase();
          const userGrades = grades[userId].filter((grade) => {
            const assignmentName = assignments.find((assignment) => assignment.id === grade.assessmentID)?.assignmentName || 'Unknown assignment';

            // Als courseId aanwezig is, filter de grades op courseId
            if (courseId && grade.courseId !== courseId) {
              return false;
            }

            // Als er een zoekopdracht is, filter op de naam van de gebruiker of opdracht
            return fullName.includes(searchQuery) || assignmentName.toLowerCase().includes(searchQuery);
          });

          if (userGrades.length) acc[userId] = userGrades;
          return acc;
        }, {});
      }

      setFilteredGrades(filtered);
    };

    fetchData();
  }, [grades, assignments, getUserData, searchQuery, courseId, currentUser]);


  // ClassRooms ophalen voor elke gebruiker
  useEffect(() => {
    const loadClassRooms = async () => {
      const classRoomPromises = Object.entries(filteredGrades).map(async ([userId, userGrades]) => {
        const user = getUserData(userId);

        // Controleer of user en userGrades gedefinieerd zijn
        if (user && userGrades.length > 0) {
          const classRoomId = (user.attendingClasses || []).find(classId => classId === userGrades[0].courseId);

          // Controleer of classRoomId geldig is en nog niet is opgehaald
          if (classRoomId && !classRooms[classRoomId]) {
            const classRoom = await getClassRoomById(classRoomId);
            setClassRooms(prevClassRooms => ({ ...prevClassRooms, [classRoomId]: classRoom }));
          }
        }
      });
      await Promise.all(classRoomPromises);
    };

    loadClassRooms();
  }, [filteredGrades, getClassRoomById, classRooms]);

  const handleDeleteGrade = (gradeId, userID) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this grade?");
    if (confirmDelete) {
      try {
        deleteGrade(gradeId, userID);
        showNotification('success', 'The grade was removed!');
      } catch (e) {
        showNotification('danger', 'Something went wrong');
      }
    }
  };

  const resultBadgeStyle = (averageResult) => {
    if (averageResult === 'N/A') {
      return 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300';
    }
    const result = parseFloat(averageResult);
    if (result >= 0 && result < 50) {
      return 'bg-red-500 dark:bg-red-700 text-white';
    } else if (result >= 50 && result < 70) {
      return 'bg-blue-500 dark:bg-blue-700 text-white';
    } else if (result >= 70 && result <= 100) {
      return 'bg-green-500 dark:bg-green-700 text-white';
    }
    return 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300';
  };

  const handleGradeClick = (gradeId) => {
    showPopUp(
      <GradingProvider gradeId={gradeId}>
        <GradingDetails gradeId={gradeId} onClose={() => showPopUp(null)} />
      </GradingProvider>
    );
  };

  const sortGrades = (grades, key, direction) => {
    const sortedGrades = Object.entries(grades).reduce((acc, [userId, userGrades]) => {
      const user = getUserData(userId);
      const sortedUserGrades = userGrades.slice().sort((a, b) => {
        if (key === 'name') {
          const nameA = `${user.firstName} ${user.lastName}`.toLowerCase();
          const nameB = `${user.firstName} ${user.lastName}`.toLowerCase();
          if (nameA < nameB) return direction === 'ascending' ? -1 : 1;
          if (nameA > nameB) return direction === 'ascending' ? 1 : -1;
          return 0;
        }
        if (key === 'date') {
          const dateA = new Date(a.creationDate);
          const dateB = new Date(b.creationDate);
          return direction === 'ascending' ? dateA - dateB : dateB - dateA;
        }
        if (key === 'assessment') {
          const assignmentNameA = assignments.find((assignment) => assignment.id === a.assessmentID)?.assignmentName || 'Unknown assignment';
          const assignmentNameB = assignments.find((assignment) => assignment.id === b.assessmentID)?.assignmentName || 'Unknown assignment';
          if (assignmentNameA < assignmentNameB) return direction === 'ascending' ? -1 : 1;
          if (assignmentNameA > assignmentNameB) return direction === 'ascending' ? 1 : -1;
          return 0;
        }
        if (key === 'result') {
          const resultA = parseFloat(a.averageResult) || 0;
          const resultB = parseFloat(b.averageResult) || 0;
          return direction === 'ascending' ? resultA - resultB : resultB - resultA;
        }
        return 0;
      });
      acc[userId] = sortedUserGrades;
      return acc;
    }, {});
    return sortedGrades;
  };

  const sortedGrades = sortGrades(filteredGrades, sortConfig.key, sortConfig.direction);

  const totalPages = Math.ceil(Object.keys(sortedGrades).reduce((acc, userId) => acc + (sortedGrades[userId]?.length || 0), 0) / itemsPerPage);

  const indexOfLastGrade = currentPage * itemsPerPage;
  const indexOfFirstGrade = indexOfLastGrade - itemsPerPage;
  const currentGrades = Object.entries(sortedGrades).reduce((acc, [userId, userGrades]) => {
    if (userGrades && Array.isArray(userGrades)) {
      return acc.concat(userGrades.map(grade => ({ userId, grade })));
    }
    return acc;
  }, []).slice(indexOfFirstGrade, indexOfLastGrade);

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-2">
            <HiOutlineAcademicCap className="text-4xl text-gray-700 dark:text-gray-300" />
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Grades</h1>
          </div>
          <div className="flex space-x-2">
            <form className="flex items-center" onSubmit={(e) => e.preventDefault()}>
              <input
                type="search"
                id="default-search"
                className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Search users"
                onChange={handleSearchChange}
              />
            </form>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          {isLoading ? (
            <SkeletonLoader type='row' />
          ) : (
            <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('name')}>User</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('assessment')}>Assignment Title</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('date')}>Result Date</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('result')}>Result</th>
                  <th scope="col" className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {currentGrades.length > 0 ? (
                  currentGrades.map(({ userId, grade }) => {
                    const user = getUserData(userId);
                    const assignmentName = assignments.find((assignment) => assignment.id === grade.assessmentID)?.assignmentName || 'Unknown assignment';
                    const averageResult = grade.averageResult || 'Click to see!';
                    const course = classRooms[grade.courseId] ?? {}; // Haal de course op uit de classRooms state
                    return (
                      <tr
                        key={grade?.id}
                        className="hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <td className="font-medium px-6 py-4 whitespace-nowrap flex items-center">
                          <img src={user.photo_url || defaultImage} alt={`${user.firstName} ${user.lastName}`} className="h-10 w-10 rounded-full object-cover mr-2" />
                          <div>
                            <div className="text-sm font-medium text-gray-900 dark:text-white">{`${user.firstName} ${user.lastName}`}</div>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-gray-900 dark:text-white">{grade.assessmentName}</td>
                        <td className="px-6 py-4 text-gray-900 dark:text-white">{grade.creationDate}</td>
                        <td className="px-6 py-4">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${resultBadgeStyle(averageResult)}`}>
                            {averageResult}
                          </span>
                        </td>
                        <td className="px-6 py-4 flex items-center space-x-2">
                          <HiOutlineEye
                            className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 dark:hover:text-blue-400 h-6 w-6"
                            onClick={() => handleGradeClick(grade.id)} // Zorg ervoor dat de gradingdetails als popup worden getoond
                          />
                          {currentUser?.roleName === 'Admin' && (
                            <>
                              <HiOutlineTrash
                                className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-red-500 dark:hover:text-red-400 h-6 w-6"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteGrade(grade.id, user?.id);
                                }}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center py-4">No grades available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="flex justify-center items-center mt-4 p-6">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            numItemsPerPage={itemsPerPage}
            onNumItemsPerPageChange={handleNumItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
}
