import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiHome } from 'react-icons/hi';
import { useData } from '../../contexts/DashboardContext';
import SkeletonLoader from '../../components/SkeletonLoader';
import LatestSubmissions from '../../data/LatestSubmissions';
import CourseAnalyticsGraph from '../../data/CourseAnalyticsGraph';
import UpcomingAssignments from '../../data/UpcomingAssignments';
import ProgressionGraph from '../../data/ProgressionGraph';
import SalesCalculator from './components/SalesCalculator';
import { useUser } from '../../contexts/UserContext';

export default function Dashboard() {
  const { courses, rolesCount, snapshotExists, isLoading } = useData(); // Gebruik data en loading state vanuit de context
  const currentUser = useUser(); // Gebruik de huidige ingelogde gebruiker
  const navigate = useNavigate();

  // Als de data nog aan het laden is, toon een SkeletonLoader
  if (isLoading || !snapshotExists) {
    return <SkeletonLoader type="card" />;
  }

  // Als de huidige gebruiker geen roleName heeft
  if (!currentUser || !currentUser.roleName) {
    return <div>No role information available</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <div className=" bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
        <div className="flex items-center mb-8">
          <HiHome className="text-3xl text-blue-600 dark:text-blue-400" />
          <h1 className="ml-4 text-3xl font-bold text-gray-900 dark:text-white">Dashboard</h1>
        </div>

        {/* Admin Dashboard */}
        {currentUser.roleName === 'Admin' && (
          <>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
              <div className="xl:col-span-3">
                <ProgressionGraph />
              </div>
              <TotalItems title="Total Users" total={rolesCount.admin + rolesCount.student + rolesCount.teacher} />
              <TotalItems title="Total Pending Registrations" total={rolesCount.totalPendingUsers || 0} />
              <TotalItems title="Total Courses" total={courses.length} />
              <TotalItems title="Total Schools" total={rolesCount.totalSchools || 0} />
              <TotalItems title="Total Grades" total={rolesCount.totalGrades || 0} />
              <TotalItems title="Total Assessments" total={rolesCount.totalAssessments || 0} />
              <TotalItems title="Total Modules" total={rolesCount.totalModules || 0} />
              <TotalItems title="Total Scenarios" total={rolesCount.totalScenarios || 0} />

              <div className="xl:col-span-3">
                <CourseAnalyticsGraph />
              </div>
              <br />

              <UpcomingAssignments limit={20} />

              <LatestSubmissions limit={35} />

            </div>

            <SalesCalculator />
          </>
        )}

        {/* Teacher/Student Dashboard */}
        {(currentUser.roleName === 'Teacher' || currentUser.roleName === 'Student') && (
          <>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
              {/* Zorg dat CourseAnalyticsGraph de hele breedte inneemt */}
              <div className="xl:col-span-3">
                <CourseAnalyticsGraph />
              </div>
              <div className="xl:col-span-2">
                <UpcomingAssignments limit={15} />
              </div>
              <LatestSubmissions limit={15} />
            </div>
          </>
        )}

      </div>
    </div>
  );
}

// Helper component for counting total items
const TotalItems = ({ title, total }) => (
  <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
    <div className="w-full">
      <h3 className="text-base font-normal text-gray-500 dark:text-gray-400">{title}</h3>
      <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">{total}</span>
    </div>
  </div>
);
