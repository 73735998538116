import React, { useState, useEffect } from 'react';
import { arrayUnion } from 'firebase/firestore';
import { useNotification } from '../../../contexts/NotificationContext';
import { usePopUp } from '../../../contexts/PopUpContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { useUser } from '../../../contexts/UserContext';
import { assignmentCollection, coursesCollection } from '../../../firebase';
import { addDocument, updateDocument } from '../../../services/FirebaseService';

export default function CreateAssignment({ classroomId, onClose, preselectedScenarioId }) {
  const [assignmentName, setAssignmentName] = useState('');
  const [selectedScenario, setSelectedScenario] = useState(preselectedScenarioId || '');
  const [selectedCourse, setSelectedCourse] = useState(classroomId || '');
  const [coursetaSearchQuery, setCoursetaSearchQuery] = useState('');
  const [templatesSearchQuery, setTemplatesSearchQuery] = useState('');
  const [formattedDueDate, setFormattedDueDate] = useState('');
  const [formattedDueTime, setFormattedDueTime] = useState('');
  const [courseSearchQuery, setCourseSearchQuery] = useState('');

  const { scenarios, courses, setCourses } = useAppData();
  const { showNotification } = useNotification();
  const { showPopUp } = usePopUp();
  const user = useUser();

  useEffect(() => {
    if (preselectedScenarioId) {
      setSelectedScenario(preselectedScenarioId);
    }
    if (classroomId) {
      setSelectedCourse(classroomId);
    }
  }, [preselectedScenarioId, classroomId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedScenario) {
      showNotification('danger', 'Please select a scenario!');
      return;
    }

    if (!selectedCourse) {
      showNotification('danger', 'Please select a course!');
      return;
    }

    if (!formattedDueDate || !formattedDueTime) {
      showNotification('danger', 'Please select a due date and time!');
      return;
    }

    // Combine date and time into a single Date object
    const dueDate = new Date(`${formattedDueDate}T${formattedDueTime}`);

    const assignmentData = {
      creationDate: new Date(),
      scenarioId: selectedScenario,
      assignmentName,
      dueDate,
      courseId: selectedCourse,
      creatorId: user.id,
      isActive: false // Indicatie voor of die wel of niet bij een course zit
    };

    try {
      const assignmentId = await addDocument(assignmentData, assignmentCollection);
      console.log('Assignment Created with ID:', assignmentId);

      await updateDocument(selectedCourse, coursesCollection, {
        assessments: arrayUnion(assignmentId),
      });

      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course.id === selectedCourse
            ? { ...course, assessments: [...(course.assessments || []), assignmentId] }
            : course
        )
      );

      showNotification('success', `Assignment Created Successfully!`);
      showPopUp(null);
    } catch (error) {
      console.error('Error creating assignment:', error);
      showNotification('danger', 'Failed to create assignment');
    }
  };

  const filteredCoursetaScenarios = scenarios.filter(scenario =>
    scenario.fromCourseta && scenario.name?.toLowerCase().includes(coursetaSearchQuery.toLowerCase())
  );

  const filteredTemplates = scenarios.filter(scenario =>
    !scenario.fromCourseta && scenario.name.toLowerCase().includes(templatesSearchQuery.toLowerCase())
  );

  const filteredCourses = courses
    .filter(course => course.published && course.id !== '66Fqzp90Cib8lmwCN3QZ')
    .filter(course => course.className?.toLowerCase().includes(courseSearchQuery.toLowerCase()));

  const handleCourseSelect = (courseId) => {
    setSelectedCourse(courseId);
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${onClose ? 'flex' : 'hidden'} items-center justify-center`}>
      <div className="flex items-center justify-center min-h-screen relative p-4 w-full max-w-2xl h-full md:h-auto">
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Assignment</h3>
            <button onClick={onClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto justify content-end dark:hover:bg-gray-600 dark:hover:text-white">
              <span className="sr-only">Close modal</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <input
                type="text"
                id="assignmentName"
                value={assignmentName}
                onChange={(e) => setAssignmentName(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Enter assignment name"
                required
              />
            </div>

            {/* Due Date Input */}
            <div className="mb-6">
              <label htmlFor="formattedDueDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Due Date</label>
              <input
                type="date"
                id="formattedDueDate"
                value={formattedDueDate}
                onChange={(e) => setFormattedDueDate(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
              />
            </div>

            {/* Due Time Input */}
            <div className="mb-6">
              <label htmlFor="formattedDueTime" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Due Time</label>
              <input
                type="time"
                id="formattedDueTime"
                value={formattedDueTime}
                onChange={(e) => setFormattedDueTime(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
              />
            </div>

            {/* Course Selection */}
            {!classroomId && (
              <fieldset className="mb-4">
                <legend className="text-lg font-semibold text-blue-600 dark:text-blue-500 mb-2">Select a Course</legend>

                <div className="mb-4">
                  <input
                    type="text"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-3 w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Search Courses..."
                    value={courseSearchQuery}
                    onChange={(e) => setCourseSearchQuery(e.target.value)}
                  />
                </div>

                <div className="max-h-64 overflow-y-auto mb-4">
                  {filteredCourses.length > 0 ? (
                    filteredCourses.map((course) => (
                      <div key={course.id} className="course-item">
                        <input
                          type="radio"
                          id={`course-${course.id}`}
                          name="course"
                          value={course.id}
                          checked={selectedCourse === course.id}
                          onChange={() => handleCourseSelect(course.id)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label htmlFor={`course-${course.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                          {course.className}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>No available courses</p>
                  )}
                </div>
              </fieldset>
            )}

            {/* Display Preselected Course */}
            {classroomId && (
              <div className="mb-4">
                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">Preselected Course:</p>
                <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
                  {courses.find(course => course.id === classroomId)?.className}
                </div>
              </div>
            )}

            {/* Scenario Selection */}
            {!preselectedScenarioId && (
              <>
                <fieldset className="mb-4 border-b pb-4">
                  <legend className="text-lg font-semibold text-blue-600 dark:text-blue-500 mb-2">Made by Courseta</legend>

                  <div className="mb-4">
                    <input
                      type="text"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-3 w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Search Courseta scenarios..."
                      value={coursetaSearchQuery}
                      onChange={(e) => setCoursetaSearchQuery(e.target.value)}
                    />
                  </div>

                  <div className="max-h-64 overflow-y-auto mb-4">
                    {filteredCoursetaScenarios.length > 0 ? (
                      filteredCoursetaScenarios.map((scenario, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <input
                            id={`courseta-scenario-${index}`}
                            type="radio"
                            name="scenario"
                            value={scenario.id}
                            checked={selectedScenario === scenario.id}
                            onChange={(e) => setSelectedScenario(e.target.value)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label htmlFor={`courseta-scenario-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            {scenario.name}
                          </label>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-600 dark:text-gray-400">No Courseta scenarios found.</p>
                    )}
                  </div>
                </fieldset>

                <fieldset className="mb-4">
                  <legend className="text-lg font-semibold text-blue-600 dark:text-blue-500 mb-2">My Templates</legend>

                  <div className="mb-4">
                    <input
                      type="text"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-3 w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Search My Templates..."
                      value={templatesSearchQuery}
                      onChange={(e) => setTemplatesSearchQuery(e.target.value)}
                    />
                  </div>

                  <div className="max-h-64 overflow-y-auto">
                    {filteredTemplates.length > 0 ? (
                      filteredTemplates.map((template, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <input
                            id={`template-${index}`}
                            type="radio"
                            name="scenario"
                            value={template.id}
                            checked={selectedScenario === template.id}
                            onChange={(e) => setSelectedScenario(e.target.value)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label htmlFor={`template-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            {template.name}
                          </label>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-600 dark:text-gray-400">No templates found.</p>
                    )}
                  </div>
                </fieldset>
              </>
            )}

            {preselectedScenarioId && (
              <div className="mb-4">
                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">Preselected Scenario:</p>
                <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
                  {scenarios.find(scenario => scenario.id === preselectedScenarioId)?.name}
                </div>
              </div>
            )}

            <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
              Create Assignment
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
