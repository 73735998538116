import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import GradeCard from './components/GradeCard';
import Transcripts from './components/Transcripts';
import { useGrade } from './contexts/GradingContext';
import OpenQuestionCard from './components/OpenQuestionCard';
import { useNotification } from '../../contexts/NotificationContext';
import logo from '../../assets/images/Courseta_Logo.png';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../../contexts/AppDataContext';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../src/firebase';
import { ClosedQuestionCard2 } from './components/ClosedQuestionCard2';
import { HiX, HiDownload, HiOutlinePrinter } from 'react-icons/hi';
import LazyPDFDownloadButton from './PdfDownload';
import { useUser } from '../../contexts/UserContext';


const GradingDetails = ({ onClose, isPopup = true }) => {
  const [categoryPercentages, setCategoryPercentages] = useState({});
  const user = useUser();
  const [gradedUser, setuser] = useState(null);
  const [totalResult, setTotalResult] = useState(0);
  const { showNotification } = useNotification();
  const { grade, questionnaire, scenario, assessment, course } = useGrade();
  const { getUserData} = useAppData();
  // const [debriefing2Data, setDebriefing2Data] = useState(null);
  const contentRef = useRef();
  const categoryPercentagesMemo = useMemo(() => categoryPercentages, [categoryPercentages]);
  const totalResultMemo = useMemo(() => totalResult, [totalResult]);


  // useEffect(() => {
  // let isMounted = true;

  //   const fetchDebriefing2Data = async () => {
  //     if (!grade || !grade.assessmentID || !grade.debriefing_2) return;

  //     try {
  //       const assessmentRef = doc(firestore, 'assessments', grade.assessmentID);
  //       const assessmentSnap = await getDoc(assessmentRef);

  //       if (assessmentSnap.exists() && isMounted) {
  //         const assessmentData = assessmentSnap.data();
  //         const debriefingQuestions = assessmentData.debriefing_2;

  //         const fetchedDebriefing2Data = debriefingQuestions.map((questionData, index) => {
  //           const userAnswerIndex = grade.debriefing_2[index];
  //           return {
  //             question: questionData.question,
  //             answers: questionData.answers,
  //             correctAnswer: questionData.correctAnswer,
  //             userAnswer: userAnswerIndex,
  //             rationale: questionData.rationale,
  //           };
  //         });

  //         setDebriefing2Data(fetchedDebriefing2Data);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching debriefing data:', error);
  //       showNotification('danger', 'Failed to fetch debriefing data');
  //     }
  //   };

  //   fetchDebriefing2Data();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [grade]);

  useEffect(() => {
    let isMounted = true;

    const fetchuser = async () => {
      if (!grade || !getUserData) return;

      try {
        const data = await getUserData(grade.userID);
        if (isMounted) {
          setuser(data);
        }
      } catch (error) {
        showNotification('danger', 'Failed to fetch user data');
      }
    };

    fetchuser();

    return () => {
      isMounted = false;
    };
  }, [grade, getUserData]);

  const calculateAllPercentages = useCallback((grade, questionnaire) => {
    const desiredCategories = ['Communication', 'Accuracy', 'Safety'];
    const percentages = desiredCategories.reduce((acc, category) => {
      const categoryData = grade[category];
      if (categoryData) {
        let totalQuestions = 0;
        let correctAnswers = 0;

        Object.entries(categoryData).forEach(([key, value]) => {
          const [achievedScore, maxScore] = value.split('/').map(Number);
          totalQuestions += maxScore;
          correctAnswers += achievedScore;
        });
        acc[category] = Number((correctAnswers / totalQuestions * 100).toFixed(1));
      } else {
        acc[category] = 0;
      }
      return acc;
    }, {});

    if (grade?.Answers && questionnaire) {
      const maxScore = questionnaire.questions.length;
      const score = questionnaire.questions.reduce((acc, question, index) => {
        const answerIndices = grade?.Answers[String(index)]
          ? grade.Answers[String(index)].split(';').map(Number)
          : [];

        const questionScore = answerIndices.reduce((questionAcc, answerIndex) => {
          const answerOption = question?.answers[answerIndex];
          return answerOption && answerOption.isCorrect ? questionAcc + 1 : questionAcc;
        }, 0);

        return acc + Math.min(questionScore, 1);
      }, 0);

      percentages['Answers'] = Number(((score / maxScore) * 100).toFixed(1));
    }

    setCategoryPercentages(percentages);
  }, []);

  useEffect(() => {
    if (grade && questionnaire) {
      calculateAllPercentages(grade, questionnaire);
    }
  }, [grade, questionnaire, calculateAllPercentages]);

  useEffect(() => {
    if (categoryPercentages) {
      calculateTotalResult(categoryPercentages);
    }
  }, [categoryPercentages]);

  const calculateTotalResult = useCallback((percentages) => {
    const totalScore = Object.values(percentages).reduce((acc, score) => acc + score, 0);
    const categoriesCount = Object.values(percentages).length;
    const averageScore = categoriesCount > 0 ? totalScore / categoriesCount : 0;
    const averageScoreTwoDecimals = parseFloat(averageScore.toFixed(2));
    setTotalResult(averageScoreTwoDecimals);
  }, []);

  const updateGradeResult = useCallback(async (averageResult, communication, safety, accuracy, answers) => {
    if (!grade?.id) return;

    try {
      const gradeRef = doc(firestore, 'grades', grade.id);

      const updateData = {
        averageResult,
        communication,
        safety,
        accuracy,
        answers,
      };

      await updateDoc(gradeRef, updateData);

    } catch (error) {
      console.error(error);
      showNotification('danger', 'Failed to update grade');
    }
  }, [grade, showNotification]);

  useEffect(() => {
    const communication = categoryPercentages.Communication || 0;
    const safety = categoryPercentages.Safety || 0;
    const accuracy = categoryPercentages.Accuracy || 0;
    const answers = categoryPercentages.Answers || 0;

    const averageScoreTwoDecimals = totalResult;

    updateGradeResult(
      averageScoreTwoDecimals,
      // assessmentName,
      // assessmentId,
      // courseName,
      // courseId,
      communication,
      safety,
      accuracy,
      answers
    );
  }, [categoryPercentages, totalResult]);

  const RenderGradeCards = React.memo(({ categoryData }) => {
    if (!categoryData) return null;

    return Object.entries(categoryData).map(([key, value], index) => {
      const [title, description] = key.split('/').map((text) => text.trim());
      const [achievedScore, maxScore] = value.split('/').map(Number);

      let status;
      if (achievedScore === maxScore) {
        status = 'success';
      } else if (achievedScore === 0) {
        status = 'error';
      } else {
        status = 'alert';
      }

      return (
        <div key={`${title}-${index}`} className="pt-4 px-4">
          <GradeCard
            title={title}
            description={description}
            score={achievedScore}
            maxScore={maxScore}
            status={status}
          />
        </div>
      );
    }).filter(Boolean);
  });

  const renderQuestions = (questions, grade) => {
    return (
      <div className="mb-8 mt-8 p-6 bg-white shadow-lg rounded-md">
        <table className="w-full border-collapse border border-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6 text-left border border-gray-200">Category</th>
              <th scope="col" className="py-3 px-6 text-right border border-gray-200">Selected Answer(s)</th>
              <th scope="col" className="py-3 px-6 text-right border border-gray-200">Points Earned</th>
            </tr>
          </thead>
          <tbody>
            {questions?.map((question, index) => {
              const answerIndicesString = grade?.Answers && grade.Answers[String(index)] !== undefined
                ? grade.Answers[String(index)]
                : '';
              const answerIndices = answerIndicesString.split(';').map(Number).filter(index => !isNaN(index));
              const hasAnswered = answerIndices.length > 0;

              const selectedChoices = hasAnswered
                ? answerIndices.map(answerIndex => question.answers[answerIndex]?.answer).filter(Boolean).join(' AND ')
                : 'No answer given';

              const correctAnswersCount = answerIndices.reduce((count, answerIndex) => {
                const answer = question.answers[answerIndex];
                return count + (answer && answer.isCorrect ? 1 : 0);
              }, 0);

              const isCorrect = correctAnswersCount === answerIndices.length;
              const pointsText = hasAnswered
                ? `${correctAnswersCount}/${answerIndices.length} Points Earned`
                : '0/1 Points Earned';

              return (
                <tr key={index}>
                  <td className="py-3 px-6 border border-gray-200">{question.questionTitle}</td>
                  <td className={`py-3 px-6 text-right border border-gray-200 ${isCorrect ? 'text-green-500' : 'text-red-500'}`}>
                    {selectedChoices}
                  </td>
                  <td className={`py-3 px-6 text-right border border-gray-200 ${isCorrect ? 'text-green-500' : 'text-red-500'}`}>
                    {pointsText}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDebriefing2 = () => {
    if (!grade?.debriefing_2) {
      return <p>Loading debriefing questions...</p>;
    }
    console.log(grade?.debriefing_2);
    const scenarioDebriefing = scenario?.debriefing_2;
    const gradeDebriefing = grade?.debriefing_2;
    return scenarioDebriefing?.map((data, index) => (
      <ClosedQuestionCard2
        key={index}
        data={{
          question: data.question,
          answers: data.answers,
          correctAnswer: data.correctAnswer,
          userAnswer: gradeDebriefing ? gradeDebriefing[index] : "unanswered",
          rationale: data.rationale,
        }}
      />
    ));
  };

  const renderOpenQuestions = (questions, answers) => {
    if (!questions || questions.length === 0) {
      return <div>No questions available.</div>;
    }

    return questions.map((question, index) => {
      const answer = answers && answers[index] !== "unanswered" ? answers[index] : "You did not answer this question.";

      return (
        <OpenQuestionCard
          key={index}
          question={question}
          answer={answer}
          date={grade?.creationDate}
          firstName={user?.firstName}
          lastName={user?.lastName}
        />
      );
    });
  };

  return isPopup ? (
    <div className="fixed inset-0 z-50 bg-gray-900 bg-opacity-50 flex items-center justify-center p-8">
      <div className="relative bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-4">
        <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg no-print">
          <h2 className="text-xl font-semibold">
            {assessment ? `${assessment.assignmentName} ${gradedUser?.firstName} ${gradedUser?.lastName}` : 'Loading...'}
          </h2>
          <div className="flex space-x-4 z-100">
            {
              //assessment && course && 
              gradedUser &&
              user && (
              <LazyPDFDownloadButton
                user={user}
                grade={grade}
                course={grade}
                assessment={assessment}
                categoryPercentages={categoryPercentages}
                totalResult={totalResult}
                debriefing2Data={grade?.debriefing_2}
                transcript={grade?.transcript}  // Pass the transcript here
                logo={logo}
                scenario={scenario}
                reflection={grade?.reflection}
                questionnaire={questionnaire}
              />
            )}
            <button onClick={onClose} className="text-white hover:text-gray-300">
              <HiX className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Content Area */}
        <div ref={contentRef} className="bg-white p-6 overflow-y-auto max-h-[80vh] printable">
          <div className="space-y-8">
            {/* PERFORMANCE OVERVIEW */}
            <section className="bg-blue-50 rounded-lg p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-blue-900">PERFORMANCE OVERVIEW</h3>
                <img src={logo} alt="CourseTA" className="h-20 w-28" />
              </div>
              <div className="bg-blue-600 h-1 mt-4"></div>

              <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-sm font-medium text-gray-700">Student:</p>
                  <p className="text-lg font-semibold text-gray-900">{gradedUser?.firstName + ' ' + gradedUser?.lastName}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Assignment:</p>
                  <p className="text-lg font-semibold text-gray-900">{grade?.assessmentName}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Course:</p>
                  <p className="text-lg font-semibold text-gray-900">{grade?.courseName}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Submission Date:</p>
                  <p className="text-lg font-semibold text-gray-900">{grade?.creationDate}</p>
                </div>
              </div>

              <div className="mt-6">
                <h4 className="text-lg font-semibold text-blue-900">Scores</h4>
                <table className="w-full mt-4 bg-white shadow-sm rounded-lg">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="p-2 text-left text-sm font-medium text-gray-700">Metric</th>
                      <th className="p-2 text-right text-sm font-medium text-gray-700">Score</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {Object.keys(categoryPercentagesMemo).map((metric) => (
                      <tr key={metric}>
                        <td className="p-2 text-sm text-gray-700">{metric}</td>
                        <td className="p-2 text-right text-sm text-gray-900">{categoryPercentagesMemo[metric]}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="flex justify-between items-center mt-6 p-4 bg-blue-50 rounded-lg">
                  <div>
                    <p className="text-sm font-medium text-blue-900">Total Score:</p>
                    <p className="text-lg font-semibold text-blue-900">{totalResultMemo}%</p>
                  </div>
                  <p className={`font-bold text-lg ${totalResultMemo >= 70 ? 'text-green-500' : totalResultMemo >= 50 ? 'text-yellow-500' : 'text-red-500'}`}>
                    {totalResultMemo >= 70 ? 'Awesome Job!' :
                      totalResultMemo >= 50 ? 'Good Job!' :
                        totalResultMemo >= 30 ? 'Almost there!' :
                          'You can do better!'}
                  </p>
                </div>
              </div>
            </section>

            {/* ACCURACY PERFORMANCE */}
            <section className="bg-blue-50 rounded-lg p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-blue-900">ACCURACY PERFORMANCE</h3>
                <img src={logo} alt="CourseTA" className="h-20 w-28" />
              </div>
              <div className="bg-blue-600 h-1 mt-4"></div>
              <RenderGradeCards categoryData={grade?.Accuracy} />
            </section>

            {/* COMMUNICATION PERFORMANCE */}
            <section className="bg-blue-50 rounded-lg p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-blue-900">COMMUNICATION PERFORMANCE</h3>
                <img src={logo} alt="CourseTA" className="h-20 w-28" />
              </div>
              <div className="bg-blue-600 h-1 mt-4"></div>
              <RenderGradeCards categoryData={grade?.Communication} />
            </section>

            {/* SAFETY PERFORMANCE */}
            <section className="bg-blue-50 rounded-lg p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-blue-900">SAFETY PERFORMANCE</h3>
                <img src={logo} alt="CourseTA" className="h-20 w-28" />
              </div>
              <div className="bg-blue-600 h-1 mt-4"></div>
              <RenderGradeCards categoryData={grade?.Safety} />
            </section>

            {/* ANSWERS ACCURACY */}
            <section className="bg-blue-50 rounded-lg p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-blue-900">ANSWERS ACCURACY</h3>
                <img src={logo} alt="CourseTA" className="h-20 w-28" />
              </div>
              <div className="bg-blue-600 h-1 mt-4"></div>
              {questionnaire ? renderQuestions(questionnaire.questions, grade) : "Loading..."}
            </section>

            {/* DEBRIEFING */}
            <section className="bg-blue-50 rounded-lg p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-blue-900">DEBRIEFING</h3>
                <img src={logo} alt="CourseTA" className="h-20 w-28" />
              </div>
              <div className="bg-blue-600 h-1 mt-4"></div>
              {renderOpenQuestions(scenario?.debriefing, grade?.debriefing)}
            </section>

            {/* CLINICAL JUDGEMENT DEBRIEFING */}
            <section className="bg-blue-50 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-blue-900">CLINICAL JUDGEMENT DEBRIEFING</h3>
              <div className="bg-blue-600 h-1 mt-4"></div>
              {renderDebriefing2(scenario?.debriefing_2, grade?.debriefing_2)}
            </section>

            {/* REFLECTION DATA */}
            <section className="bg-blue-50 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-blue-900">REFLECTION DATA</h3>
              <div className="bg-blue-600 h-1 mt-4"></div>
              {renderOpenQuestions(scenario?.reflection, grade?.reflection)}
            </section>

            {/* SIMULATION TRANSCRIPT */}
            <section className="bg-blue-50 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-blue-900">SIMULATION TRANSCRIPT</h3>
              <div className="bg-blue-600 h-1 mt-4"></div>
              <div className="p-4">
                <Transcripts />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div ref={contentRef} className="bg-white p-6 overflow-y-auto max-h-[80vh] printable">
      <div className="space-y-8">
        {/* PERFORMANCE OVERVIEW */}
        <section className="bg-blue-50 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-blue-900">PERFORMANCE OVERVIEW</h3>
            <img src={logo} alt="CourseTA" className="h-20 w-28" />
          </div>
          <div className="bg-blue-600 h-1 mt-4"></div>

          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <p className="text-sm font-medium text-gray-700">Student:</p>
              <p className="text-lg font-semibold text-gray-900">{user?.firstName + ' ' + user?.lastName}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-700">Assignment:</p>
              <p className="text-lg font-semibold text-gray-900">{assessment?.assignmentName}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-700">Course:</p>
              <p className="text-lg font-semibold text-gray-900">{course?.className}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-700">Submission Date:</p>
              <p className="text-lg font-semibold text-gray-900">{grade?.creationDate}</p>
            </div>
          </div>

          <div className="mt-6">
            <h4 className="text-lg font-semibold text-blue-900">Scores</h4>
            <table className="w-full mt-4 bg-white shadow-sm rounded-lg">
              <thead className="bg-gray-100">
                <tr>
                  <th className="p-2 text-left text-sm font-medium text-gray-700">Metric</th>
                  <th className="p-2 text-right text-sm font-medium text-gray-700">Score</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {Object.keys(categoryPercentagesMemo).map((metric) => (
                  <tr key={metric}>
                    <td className="p-2 text-sm text-gray-700">{metric}</td>
                    <td className="p-2 text-right text-sm text-gray-900">{categoryPercentagesMemo[metric]}%</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-between items-center mt-6 p-4 bg-blue-50 rounded-lg">
              <div>
                <p className="text-sm font-medium text-blue-900">Total Score:</p>
                <p className="text-lg font-semibold text-blue-900">{totalResultMemo}%</p>
              </div>
              <p className={`font-bold text-lg ${totalResultMemo >= 70 ? 'text-green-500' : totalResultMemo >= 50 ? 'text-yellow-500' : 'text-red-500'}`}>
                {totalResultMemo >= 70 ? 'Awesome Job!' :
                  totalResultMemo >= 50 ? 'Good Job!' :
                    totalResultMemo >= 30 ? 'Almost there!' :
                      'You can do better!'}
              </p>
            </div>
          </div>
        </section>

        {/* ACCURACY PERFORMANCE */}
        <section className="bg-blue-50 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-blue-900">ACCURACY PERFORMANCE</h3>
            <img src={logo} alt="CourseTA" className="h-20 w-28" />
          </div>
          <div className="bg-blue-600 h-1 mt-4"></div>
          <RenderGradeCards categoryData={grade?.Accuracy} />
        </section>

        {/* COMMUNICATION PERFORMANCE */}
        <section className="bg-blue-50 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-blue-900">COMMUNICATION PERFORMANCE</h3>
            <img src={logo} alt="CourseTA" className="h-20 w-28" />
          </div>
          <div className="bg-blue-600 h-1 mt-4"></div>
          <RenderGradeCards categoryData={grade?.Communication} />
        </section>

        {/* SAFETY PERFORMANCE */}
        <section className="bg-blue-50 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-blue-900">SAFETY PERFORMANCE</h3>
            <img src={logo} alt="CourseTA" className="h-20 w-28" />
          </div>
          <div className="bg-blue-600 h-1 mt-4"></div>
          <RenderGradeCards categoryData={grade?.Safety} />
        </section>

        {/* ANSWERS ACCURACY */}
        <section className="bg-blue-50 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-blue-900">ANSWERS ACCURACY</h3>
            <img src={logo} alt="CourseTA" className="h-20 w-28" />
          </div>
          <div className="bg-blue-600 h-1 mt-4"></div>
          {questionnaire ? renderQuestions(questionnaire.questions, grade) : "Loading..."}
        </section>

        {/* DEBRIEFING
        <section className="bg-blue-50 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-blue-900">DEBRIEFING</h3>
            <img src={logo} alt="CourseTA" className="h-20 w-28" />
          </div>
          <div className="bg-blue-600 h-1 mt-4"></div>
          {renderOpenQuestions(scenario?.debriefing, grade?.debriefing)}
        </section>

        {/* CLINICAL JUDGEMENT DEBRIEFING */}
        {/* <section className="bg-blue-50 rounded-lg p-4">
          <h3 className="text-xl font-semibold text-blue-900">CLINICAL JUDGEMENT DEBRIEFING</h3>
          <div className="bg-blue-600 h-1 mt-4"></div>
          {renderDebriefing2()}
        </section>

        {/* REFLECTION DATA */}
        {/* <section className="bg-blue-50 rounded-lg p-4">
          <h3 className="text-xl font-semibold text-blue-900">REFLECTION DATA</h3>
          <div className="bg-blue-600 h-1 mt-4"></div>
          {renderOpenQuestions(scenario?.reflection, grade?.reflection)}
        </section>  */} 

        {/* SIMULATION TRANSCRIPT */}
        <section className="bg-blue-50 rounded-lg p-4">
          <h3 className="text-xl font-semibold text-blue-900">SIMULATION TRANSCRIPT</h3>
          <div className="bg-blue-600 h-1 mt-4"></div>
          <div className="p-4">
            <Transcripts />
          </div>
        </section>
      </div>
    </div>
  );
};

export default GradingDetails;
